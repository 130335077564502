<template>
  <v-container class="ak-page-container">
    <PageTitle title="Challenges" />

    <v-row no-gutters>
      <v-col cols="12">
        <LearnUnitLevelItem
          v-for="item in challenges"
          :key="item.data.id"
          :levelTitle="item.data.name"
          :gameStatus="item.data.completed"
          :levelId="item.data.level"
          :unitId="item.data.unit"
          :isLocked="item.data.locked"
          :gameId="item.data.id"
          @updateList="updateList"
          :challengeLinkGet="item.challengeLinkGet"
          :challengeLinkPostRun="item.challengeLinkPostRun"
          :challengeLinkGetSummary="item.challengeLinkGetSummary"
          :isGame="false"
          :fromChallengePage="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageTitle from '@/components/common/PageTitle';
import LearnUnitLevelItem from '@/components/modules/LearnUnit/LearnUnitLevelItem';

export default {
  name: 'Challenges',
  components: { PageTitle, LearnUnitLevelItem },

  data() {
    return {
      challenges: [],
    };
  },

  created() {
    this.getChallengeList();
  },

  methods: {
    updateList() {
      this.getChallengeList();
    },
    getChallengeList() {
      this.challenges = [];

      this.$axios
        .get(`/api/v1/user/challenges/`, this.axiosGetConfig)
        .then((res) => {
          res.data.data.forEach((item) => {
            let postLink = null;
            let getLink = null;
            let getLinkSummary = null;
            if (item.links) {
              postLink = item.links.filter(
                (el) => el.method == 'POST' && el.rel == 'run'
              );

              getLink = item.links.filter(
                (el) => el.method == 'GET' && el.rel == 'run'
              );

              getLinkSummary = item.links.filter(
                (el) => el.method == 'GET' && el.rel == 'summary'
              );
            }

            this.challenges.push({
              data: item.data,
              links: item.links,
              challengeLinkGet:
                getLink !== null
                  ? getLink.length > 0
                    ? getLink[0].href
                    : null
                  : null,
              challengeLinkPostRun:
                postLink !== null
                  ? postLink.length > 0
                    ? postLink[0].href
                    : null
                  : null,
              challengeLinkGetSummary:
                getLinkSummary !== null
                  ? getLinkSummary.length > 0
                    ? getLinkSummary[0].href
                    : null
                  : null,
            });
          });
        });
    },
  },
};
</script>
